import React, { Component } from 'react';
import JsxParser from 'react-jsx-parser';
import InfoCard from '../../components/InfoCard';
import QueryDataGrid1 from '../../components/QueryDataGrid1';
import PaginatedQueryDataGrid from '../../components/PaginatedQueryDataGrid';
import { withRouter } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import NavTabs from '../../components/NavTabs';
import * as formatters from '../../utils/Formatters';
import i18n from '../../views/Pages/Login/i18n';
import ProcessesTable from '../ProcessesTable';
import QueryInfoCard from '../QueryInfoCard';
import QueryForm from '../QueryForm';
import TableHeader from '../../components/TableHeader';
import TableHeader2 from '../../components/TableHeader2';
import BillsTab from '../../components/BillsTab';
import QueryBillsTabWrapper from '../QueryBillsTabWrapper';
import QueryProcessesTabWrapper from '../QueryProcessesTabWrapper';
import ReadingsTab from '../../components/ReadingsTab';
import QueryNotesAndAttachments from '../QueryNotesAndAttachments';
import QueryBalance from '../QueryBalance';
import QueryLink from '../QueryLink';
import QueryBillsDetail from '../QueryBillsDetail';
import BusinessPartnerHeader from '../BusinessPartnerHeader';
import SiteHeader from '../SiteHeader';
import StartProcess from '../StartProcess';
import Tasks from '../../views/Tasks';
import SimpleDateQuery from '../SimpleDateQuery';
import { Query } from 'react-apollo';
import SignContractButton from '../SignContractButton';
import QuerySignContractButton from '../QuerySignContractButton';
import ConfigurableReport from '../ConfigurableReport';
import ConfigurableReports from '../ConfigurableReports';
import ServiceRequests from '../../views/ServiceRequests';
import { Step, Steps, Wizard } from 'react-albus';
import FormA from '../../views/SwitchingForm/FormA';
import StepA from '../../views/SwitchingForm/StepA';
import JsonSchemaForm from 'react-jsonschema-form';
import QueryWrapper from '../QueryWrapper';
import DisplayValue from '../DisplayValue';
import * as reCharts from 'recharts';
import DynamicLineChart from '../DynamicLineChart';
import ExportChartButton from '../ExportChartButton';
import SimpleDataGrid from '../SimpleDataGrid/SimpleDataGrid';
import CustomAreaChart from '../CustomAreaChart';
import ScheduledReports from '../ScheduledReports';
import ServiceHeader from '../ServiceHeader';
import LinkButton from '../LinkButton';
import CollapsableBars from '../CollapsableBars';
import QueryDropdownBtn from '../QueryDropdownBtn';
import QueryContactUsModalBtn from '../QueryContactUsModalBtn';
import Queries from '../../views/Queries';
import PaymentsView from '../../views/PaymentsView/PaymentsView';
import NominationsGrid from '../NominationsGrid/NominationsGrid';
import loadable from '@loadable/component';

const NominationsGridWrapper = loadable(() => {
  return import('../../' + window.config.consul.CLIENT + '/views/NominationsGridWrapper/NominationsGridWrapper')
    .catch(() => import('./../../views/NominationsGridWrapper/NominationsGridWrapper'))
    .catch(() => import('./../../views/Missing'));
});

class GenericView extends Component {
  constructor(props) {
    super(props);
    this.getLayoutByName = this.getLayoutByName.bind(this);
    const layout = this.getLayoutByName(this.props.layout || this.props.match.params.name);
    this.queries = this.getQueries(layout);
  }

  getLayoutByName(layoutName) {
    return this.props.configuration.uiLayouts.reduce((result, current) => {
      if (current.name === layoutName) {
        result = current;
      }
      return result;
    });
  }

  getQueries() {
    const result = {};
    const uiQueries = this.props.configuration.uiQueries;
    for (let i = 0; i < uiQueries.length; i++) {
      result[uiQueries[i].name] = uiQueries[i].query;
    }
    return result;
  }

  render() {
    if (this.props.not) {
      return (
        <Switch>
          <Route
            exact={true}
            path={'/view/:name' + this.props.path}
            render={(props) => {
              return null;
            }}
          />
          <Route
            render={(props) => {
              const { path, not, ...newProps } = this.props;
              return <GenericView {...newProps} configuration={this.props.configuration} />;
            }}
          />
        </Switch>
      );
    } else if (this.props.path) {
      return (
        <Route
          path={'/view/:name' + this.props.path}
          exact={true}
          render={(props) => {
            const { path, ...newProps } = this.props;
            return <GenericView {...newProps} configuration={this.props.configuration} />;
          }}
        />
      );
    }

    const layout = this.getLayoutByName(this.props.layout || this.props.match.params.name);
    const queries = this.getQueries(layout);
    const configuration = this.props.configuration;

    const { ResponsiveContainer, ...re } = { ...reCharts };

    return (
      <JsxParser
        bindings={{
          ...this.props,
          global: this.global,
          queries: queries,
          configuration: configuration,
          i18n: i18n,
          ...formatters,
        }}
        components={{
          Dropdown,
          DropdownToggle,
          DropdownItem,
          DropdownMenu,
          QueryDropdownBtn,
          QueryContactUsModalBtn,
          Row,
          Col,
          Nav,
          NavItem,
          TabContent,
          TabPane,
          InfoCard,
          Card,
          CardText,
          Link,
          Switch,
          Route,
          Redirect,
          QueryDataGrid1,
          Form,
          FormGroup,
          NavTabs,
          ProcessesTable,
          QueryForm,
          QueryInfoCard,
          GenericView,
          TableHeader,
          TableHeader2,
          BillsTab,
          ReadingsTab,
          QueryNotesAndAttachments,
          QueryBillsTabWrapper,
          QueryBalance,
          QueryProcessesTabWrapper,
          QueryBillsDetail,
          QueryLink,
          BusinessPartnerHeader,
          SiteHeader,
          ServiceHeader,
          Tasks,
          Queries,
          PaginatedQueryDataGrid,
          StartProcess,
          SimpleDateQuery,
          CardHeader,
          CardTitle,
          CardBody,
          Button,
          Query,
          SignContractButton,
          QuerySignContractButton,
          ConfigurableReports,
          QueryWrapper,
          DisplayValue,
          ConfigurableReport,
          ServiceRequests,
          Steps,
          Step,
          Wizard,
          FormA,
          StepA,
          JsonSchemaForm,
          DynamicLineChart,
          ...re,
          ExportChartButton,
          SimpleDataGrid,
          CustomAreaChart,
          ScheduledReports,
          LinkButton,
          CollapsableBars,
          NominationsGrid,
          NominationsGridWrapper,
          PaymentsView,
        }}
        jsx={layout.jsx}
        showWarnings={true}
      />
    );
  }
}

export default withRouter(GenericView);
