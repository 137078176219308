import React, { useState, useMemo } from "react";
import SupersetDashboard from "../../components/SupersetDashboard/SupersetDashboard";
import { Row } from "reactstrap";

const Graphs = ({ username }) => {
	const [selectedDashboard, setSelectedDashboard] = useState(
		window.config.consul.BI_DASHBOARD_IDS[0] || null
	);

	const dashboards = useMemo(() => window.config.consul.BI_DASHBOARD_IDS || []);

	const buttons = useMemo(() =>
		dashboards.map((dashboard, index) =>
			(!dashboard.roles ||
				dashboard.roles.some((role) =>
					localStorage.roles.split(",").includes(role)
				)) &&
			(!dashboard.excludedEmails ||
				!dashboard.excludedEmails.includes(username)) ? (
				<button
					key={index}
					type="button"
					className={`btn btn-primary btn-report mx-1 ${
						selectedDashboard.id === dashboard.id ? "active" : ""
					}`}
					onClick={() => handleButtonClick(dashboard)}
				>
					{dashboard.name}
				</button>
			) : null
		)
	);

	const handleButtonClick = (dashboard) => setSelectedDashboard(dashboard);

	return (
		selectedDashboard && (
			<SupersetDashboard
				key={selectedDashboard.id}
				id={selectedDashboard.id}
				supersetDomain={window.config.consul.BI_SUPERSET_DOMAIN}
				dashboardName="Графики"
			>
				<Row className="pb-3 pl-3">{buttons}</Row>
			</SupersetDashboard>
		)
	);
};

export default Graphs;
