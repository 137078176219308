import React, { useEffect, useState } from "react";
import { embedDashboard } from "@preset-sdk/embedded";

import axios from "axios";
import { Row, Col } from "reactstrap";
import Loading from "../Loading/Loading";

const SupersetDashboard = ({
	id,
	supersetDomain,
	dashboardName,
	children = null,
}) => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axios
			.get("/bi-guest-token", {
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
				params: { id },
			})
			.then(({ data }) => {
				embedDashboard({
					id: id,
					supersetDomain: supersetDomain,
					mountPoint: document.getElementById("superset-dashboard-container"),
					fetchGuestToken: () => data.token,
					dashboardUiConfig: {
						hideTitle: true,
						filters: {
							expanded: false,
						},
					},
				});
			})
			.catch((e) => console.log(e))
			.finally(() => setLoading(false));
	}, []);

	return (
		<div>
			<Row className="subheader">
				<Col className="col-8">
					<h1>{dashboardName}</h1>
				</Col>
			</Row>
			<Row className="page-cnt">
				{children}
				{loading && <Loading />}
				<div id="superset-dashboard-container"></div>
			</Row>
		</div>
	);
};

export default SupersetDashboard;
